import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";

import Layout from "../../components/layout";
import { ErrMsg } from "../../components/message";
import ConfirmBox from "../../components/confirmBox";
import Tabs from "../../components/tabs";
import Loading from "../../components/loading";

import { CurrentUserContext } from "../../providers/auth";
import Links, { getAdminLinks } from "../../utils/links";
import UserUtil, { checkPassword, Constants } from "../../utils/user";
import Services, { hasAuthError } from "../../services";

const UsersPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.Admin}
            seoTitle="User Management"
            permissionValidator={UserUtil.hasSysAdminPrivileges}
            forceCheckSessionCount={checkCount}
        >
            <Users forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default UsersPage;

const UsernameHint = "Can use letters, numbers, '-' or '_' only.";

const Users = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const userService = Services(currentUser).user;

    const [users, setUsers] = useState([]);
    // user: Current selected user in user table
    const [user, setUser] = useState(null);
    // accountName: Username of current login account
    const [accountName, setAccountName] = useState("");
    const [showAddUser, setShowAddUser] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false);
    const [showConfirmDel, setShowConfirmDel] = useState(false);
    const [delUser, setDelUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleError = (err) => {
        if (hasAuthError(err)) {
            forceCheckSession();
        } else {
            console.error(err);
        }
    };

    const userRole = (u) => {
        if (u.user_type === Constants.SYS_ADMIN) {
            return "System Admin";
        }
        if (u.user_type === Constants.DATA_OPERATOR) {
            return "Data Operator";
        }
        if (u.user_type === Constants.DATA_BROWSER) {
            return "Data Browser";
        }
    };

    const hideMainCss = () => (showAddUser || showEditUser ? " hidden" : "");

    const reload = () => {
        setShowConfirmDel(false);
        setShowAddUser(false);
        setShowEditUser(false);
        setLoading(true);
        userService
            .getUsers()
            .then((d) => setUsers(d.users))
            .catch((err) => {
                setUsers([]);
                handleError(err);
            })
            .finally(() => setLoading(false));
    };

    const newUser = (props) =>
        Object.assign(
            {},
            {
                username: "",
                email: "",
                password: "",
                rPassword: "",
                user_type: Constants.DATA_BROWSER,
                enabled: true
            },
            props
        );

    const deleteUser = () => {
        userService
            .deleteUser(delUser.username)
            .then(reload)
            .catch((err) => handleError(err))
            .finally(() => setShowConfirmDel(false));
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        currentUser
            .getUser()
            .then((u) => setAccountName(u.profile.sub))
            .catch((err) => console.error(err));
        reload();
    }, [currentUser]);

    const UsersTable = () => (
        <table
            className={
                "w-full admin-table" +
                hideMainCss() +
                (loading ? " hidden" : "")
            }
        >
            <thead>
                <tr>
                    <th style={{ width: "20%" }}>Username</th>
                    <th style={{ width: "25%" }}>Email</th>
                    <th style={{ width: "15%" }}>Role</th>
                    <th style={{ width: "10%" }}>Enabled</th>
                    <th style={{ width: "30%" }}>Actions</th>
                </tr>
            </thead>
            <tbody className="break-all">
                {users.length > 0 &&
                    users.map((u, i) => (
                        <tr key={i}>
                            <td>{u.username}</td>
                            <td>{u.email}</td>
                            <td>{userRole(u)}</td>
                            <td>{u.enabled ? "Yes" : "No"}</td>
                            <td>
                                <button
                                    className="btn-secondary mt-1 mr-3"
                                    onClick={() => {
                                        setUser(newUser(u));
                                        setShowEditUser(true);
                                    }}
                                >
                                    Edit
                                </button>
                                {u.username !== accountName && (
                                    <button
                                        className="btn-secondary mt-1"
                                        onClick={() => {
                                            setDelUser(u);
                                            setShowConfirmDel(true);
                                        }}
                                    >
                                        Delete
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );

    const ConfirmDel = () => (
        <ConfirmBox
            action={deleteUser}
            cancelAction={() => setShowConfirmDel(false)}
            deleteBtn={true}
        >
            Are you sure to delete user{" "}
            <span className="inline-block font-medium">{delUser.username}</span>{" "}
            ?
        </ConfirmBox>
    );

    return (
        <>
            <Tabs links={getAdminLinks(Links.Users)} />
            <div className={"text-right my-3" + hideMainCss()}>
                <button
                    className="btn-primary"
                    onClick={() => {
                        setUser(newUser());
                        setShowAddUser(true);
                    }}
                >
                    Add User
                </button>
            </div>
            {loading && <Loading />}

            <UsersTable />
            {showConfirmDel && <ConfirmDel />}

            {showAddUser && (
                <AddOrEditUser
                    title="Add user"
                    isEdit={false}
                    user={user}
                    setUser={setUser}
                    setShow={setShowAddUser}
                    updateFunc={userService.addUser}
                    onSuccess={reload}
                    onError={handleError}
                />
            )}
            {showEditUser && (
                <AddOrEditUser
                    title={"Edit user " + user.username}
                    isEdit={true}
                    user={user}
                    setUser={setUser}
                    setShow={setShowEditUser}
                    updateFunc={userService.updateUser}
                    onSuccess={reload}
                    onError={handleError}
                />
            )}
        </>
    );
};

Users.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const AddOrEditUser = ({
    title,
    isEdit,
    user,
    setUser,
    setShow,
    updateFunc,
    onSuccess,
    onError
}) => {
    const [userErr, setUserErr] = useState({});
    const [updateErr, setUpdateErr] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);

    const usernameRegex = /^[0-9a-z\-\_]+$/i;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const copyUser = (prop) => Object.assign({}, user, prop);

    const handleNameChange = (e) =>
        setUser(copyUser({ username: e.target.value }));

    const handleEmailChange = (e) =>
        setUser(copyUser({ email: e.target.value }));
    const handlePasswordChange = (e) =>
        setUser(copyUser({ password: e.target.value }));
    const handleRPasswordChange = (e) =>
        setUser(copyUser({ rPassword: e.target.value }));
    const handleUserTypeChange = (e) =>
        setUser(copyUser({ user_type: e.target.value }));
    const setUserEnabled = (enabled) => setUser(copyUser({ enabled }));

    const checkInputErrors = () => {
        const username = user.username.trim();
        setUser(copyUser({ username }));
        const { email, password } = user;
        let errs = {
            username:
                (!username && "Username is required.") ||
                (username && !usernameRegex.test(username) && UsernameHint) ||
                "",
            email:
                (!email && "Email is required.") ||
                (email && !emailRegex.test(email) && "Invalid email format") ||
                "",
            password: password
                ? checkPassword(password)
                : !isEdit
                ? "Password is required."
                : "",
            rPassword:
                (password &&
                    password !== user.rPassword &&
                    "Wrong password confirmation.") ||
                ""
        };
        setUserErr(errs);
        return Object.keys(errs).find((k) => errs[k]);
    };

    const addUser = () => {
        if (checkInputErrors()) {
            return;
        }
        setShowConfirm(true);
    };

    const updateUser = () => {
        setUpdateErr("");
        updateFunc(user)
            .then(onSuccess)
            .catch((err) => {
                if (err && err.code === "DUPLICATED_USER") {
                    setUserErr({
                        username: "The same username is used by another user."
                    });
                } else if (err && err.code === "RESERVED_USER" && !isEdit) {
                    setUserErr({
                        username:
                            "The username is reserved. Please use another username."
                    });
                } else {
                    onError(err);
                    setUpdateErr("Unexpected error: " + err.message);
                }
                setShowConfirm(false);
            });
    };

    const getUpdateType = () => (isEdit ? "Update" : "Add");

    const ConfirmUpdate = () => (
        <ConfirmBox
            action={updateUser}
            cancelAction={() => setShowConfirm(false)}
        >
            Are you sure to {getUpdateType().toLowerCase()} user {user.username}
            ?
        </ConfirmBox>
    );

    return (
        <>
            {showConfirm && <ConfirmUpdate />}
            <div className="mt-6 flex w-3/4">
                <div className="ml-auto w-2/3 title-h2">{title}</div>
            </div>
            {!isEdit && (
                <div className="flex w-3/4 mt-4">
                    <div className="w-1/3">
                        <label className="label-r" htmlFor="username">
                            Username
                        </label>
                    </div>
                    <div className="w-2/3">
                        <input
                            className="input"
                            id="username"
                            type="text"
                            onChange={handleNameChange}
                            value={user.username}
                            title={UsernameHint}
                        ></input>
                        <ErrMsg msg={userErr.username} />
                    </div>
                </div>
            )}
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3">
                    <label className="label-r" htmlFor="email">
                        Email
                    </label>
                </div>
                <div className="w-2/3">
                    <input
                        className="input"
                        id="email"
                        type="text"
                        onChange={handleEmailChange}
                        value={user.email}
                        required
                    ></input>
                    <ErrMsg msg={userErr.email} />
                </div>
            </div>

            <div className="flex w-3/4 mt-4">
                <div className="w-1/3">
                    <label className="label-r" htmlFor="password">
                        {isEdit ? "Update" : ""} Password
                    </label>
                </div>
                <div className="w-2/3">
                    <input
                        className="input"
                        id="password"
                        type="password"
                        onChange={handlePasswordChange}
                        value={user.password}
                        title={checkPassword("")}
                        placeholder={isEdit ? "Optional" : ""}
                    ></input>
                    <ErrMsg msg={userErr.password} />
                </div>
            </div>
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3">
                    <label className="label-r" htmlFor="rpassword">
                        Password Confirmation
                    </label>
                </div>
                <div className="w-2/3">
                    <input
                        className="input"
                        id="rpassword"
                        type="password"
                        onChange={handleRPasswordChange}
                        value={user.rPassword}
                    ></input>
                    <ErrMsg msg={userErr.rPassword} />
                </div>
            </div>

            <div className="flex w-3/4 mt-4">
                <div className="w-1/3">
                    <label className="label-r" htmlFor="type">
                        Role
                    </label>
                </div>
                <div className="w-2/3">
                    <select
                        className="input"
                        id="type"
                        type="text"
                        value={user.user_type}
                        onChange={handleUserTypeChange}
                    >
                        <option value={Constants.SYS_ADMIN}>
                            System Administrator
                        </option>
                        <option value={Constants.DATA_OPERATOR}>
                            Data Operator
                        </option>
                        <option value={Constants.DATA_BROWSER}>
                            Data Browser
                        </option>
                    </select>
                </div>
            </div>

            <div className="flex items-center w-3/4 mt-4">
                <div className="w-1/3 label-r">Enabled</div>
                <div className="w-2/3">
                    <label className="mr-8">
                        <input
                            className="mr-1"
                            name="user-enabled"
                            type="radio"
                            checked={user.enabled}
                            onChange={() => setUserEnabled(true)}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            className="mr-1"
                            name="user-enabled"
                            type="radio"
                            checked={!user.enabled}
                            onChange={() => setUserEnabled(false)}
                        />
                        No
                    </label>
                </div>
            </div>

            <div className="flex w-3/4 mt-4 pb-12">
                <div className="w-1/3"></div>
                <div className="w-2/3">
                    <button
                        className="btn-primary w-1/3 mr-4"
                        onClick={addUser}
                    >
                        {getUpdateType()}
                    </button>
                    <button
                        className="btn-secondary w-1/3"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </button>
                    <ErrMsg msg={updateErr} />
                </div>
            </div>
        </>
    );
};
AddOrEditUser.propTypes = {
    title: PropTypes.string.isRequired,
    isEdit: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        username: PropTypes.string,
        email: PropTypes.string,
        password: PropTypes.string,
        rPassword: PropTypes.string,
        user_type: PropTypes.string,
        enabled: PropTypes.bool
    }).isRequired,
    setUser: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    updateFunc: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
};
