import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import Style from "./tabs.module.css";

const Tabs = ({ links }) => (
    <>
        {links.length !== 0 && (
            <ul className={Style.tabs}>
                {links.map((l, idx) => {
                    return l.active ? (
                        <li key={idx} className={Style.active}>
                            <div className="title-h1 inline-block px-6">
                                {l.name}
                            </div>
                        </li>
                    ) : (
                        <li key={idx} className={Style.tab}>
                            <Link to={l.url} className="btn-tab px-6 text-base">
                                {l.name}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        )}
    </>
);
Tabs.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            active: PropTypes.bool,
            url: PropTypes.string.isRequired
        })
    )
};

export default Tabs;

export const SubTabs = ({ actions }) => (
    <>
        {actions.length !== 0 && (
            <ul className={Style.tabs}>
                {actions.map((a, idx) => {
                    return a.active ? (
                        <li
                            key={idx}
                            className={Style.active + " " + Style.tabLv2}
                        >
                            {a.name}
                        </li>
                    ) : (
                        <li
                            key={idx}
                            className={Style.tab + " " + Style.tabLv2}
                        >
                            <button className="btn-link" onClick={a.action}>
                                {a.name}
                            </button>
                        </li>
                    );
                })}
            </ul>
        )}
    </>
);
SubTabs.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            active: PropTypes.bool,
            action: PropTypes.func
        })
    ).isRequired
};
